import React, { lazy, Suspense, useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useStore } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { setDefaultTransformResponse } from "@coralblack/flax";
import { loadTheme } from "@fluentui/react";
import { SSO } from "@pages/auth/SSO";
import { ErrorLayout } from "@pages/system/layout/Error";
import { MsaFxGuard } from "./MsaFxGuard";
import pkg from "../package.json";
import { dialogIfTokenDestroyedByNewToken } from "@maven-surface/components/common/auth/TokenInvalidDialog";
import { MsaAuthRoute } from "@maven-surface/components/common/MsaAuthRoute";
import { RootState } from "@maven-rest/states/index";
import { classNames } from "@maven-helper/functions/Misc.function";

import "@maven-surface/assets/style/App.scss";
import "@coralblack/flax/dist/styles/index.css";

loadTheme({
  defaultFontStyle: { fontFamily: '"Nunito", "Noto Sans KR", sans-serif', fontWeight: "400" },
  fonts: {
    xSmall: {
      fontSize: "0.69rem",
    },
    small: {
      fontSize: "0.75rem",
    },
    medium: {
      fontSize: "0.82rem",
    },
    large: {
      fontSize: "0.91rem",
    },
    xLarge: {
      fontSize: "1rem",
    },
  },
});

if (process.env.REACT_APP_STAGE !== "local") {
  datadogRum.init({
    applicationId: "9505e2fe-4571-47f0-9b2c-363aa41c69c0",
    clientToken: "pub60cc606514b30873c6c758a2bc17c0d9",
    site: "datadoghq.com",
    service: "com.mavenclinical.safety",
    env: process.env.REACT_APP_STAGE?.toLowerCase() || "UNKNOWN",
    version: pkg.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });

  datadogRum.startSessionReplayRecording();
}

const HomeLayout = lazy(() => import("@layouts/home/Index"));
const WorkspaceLayout = lazy(() => import("@layouts/workspace/Index"));
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: () => false,
    },
  },
});

function App() {
  const session = useStore<RootState>().getState().session;
  const appBadge = process.env.REACT_APP_BADGE;
  const appBadgeDisabled = process.env.REACT_APP_BADGE_DISABLE;

  useEffect(() => {
    document.body.classList.add("ready");

    setTimeout(() => {
      document.getElementById("spinner")?.remove();
    }, 1500);
  });

  setDefaultTransformResponse([
    function (data) {
      const o = JSON.parse(data);

      dialogIfTokenDestroyedByNewToken(o);

      return o;
    },
  ]);

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <div className="app-wrapper">
          {!appBadgeDisabled && appBadge && (
            <div className={classNames("notranslate app-sidebar-ribbon", `--${String(appBadge).toLowerCase()}`)}>{appBadge}</div>
          )}
          <Suspense
            fallback={
              <div className="suspense-loading">
                <div className="loading">
                  <div className="loader" />
                </div>
              </div>
            }
          >
            <Switch>
              <Route exact={true} path="/404" render={ErrorLayout} />
              <Route exact={true} path="/auth" render={() => <SSO session={session} />} />
              <MsaAuthRoute
                authStrategy={"AUTHORIZED_ONLY"}
                session={session}
                path="/workspaces/:workspaceId(\d+)"
                render={() => <MsaFxGuard component={WorkspaceLayout} />}
              />
              <MsaAuthRoute
                authStrategy={"AUTHORIZED_ONLY"}
                session={session}
                path="/"
                render={() => <MsaFxGuard component={HomeLayout} />}
              />
              <Redirect from="*" to="/404?r=rt" />
            </Switch>
          </Suspense>
        </div>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
